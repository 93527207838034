import { httpService } from 'core-utilities';
import {
  getUsersUniversesListConfig,
  getGroupsUniversesListConfig,
  getImpressionCostEstimateConfig,
  getCreateAdConfig,
  getAdsMetadataConfig,
  getAgeRecommendationConfig
} from '../utils/urlHelper';

export const getImpressionCostEstimate = formData => {
  const urlConfig = getImpressionCostEstimateConfig();
  return httpService.get(urlConfig, formData);
};

export const submitCreateAd = formData => {
  const urlConfig = getCreateAdConfig();
  return httpService.post(urlConfig, formData);
};

export const getGamesList = groupId => {
  return new Promise(resolve => {
    const pullGames = pageCursor => {
      if (groupId) {
        const urlConfig = getGroupsUniversesListConfig(groupId, pageCursor);
        return httpService.get(urlConfig);
      }
      const urlConfig = getUsersUniversesListConfig(pageCursor);
      return httpService.get(urlConfig);
    };

    let gameList = [];
    let nextPageCursor = null;
    const handleError = ({ data }) => {
      if (data.errors && data.errors[0]) {
        const { userFacingMessage } = data.errors[0];
        console.log(userFacingMessage);
      }
    };
    const handleGamesResponse = ({ data }) => {
      nextPageCursor = null;
      if (data) {
        gameList = gameList.concat(data.data);
        nextPageCursor = data.nextPageCursor;
      }
      if (nextPageCursor) {
        pullGames(nextPageCursor).then(handleGamesResponse, handleError);
      } else {
        resolve(gameList);
      }
    };

    pullGames(nextPageCursor).then(handleGamesResponse, handleError);
  });
};

export const getAdsMetadata = () => {
  const urlConfig = getAdsMetadataConfig();
  return httpService.get(urlConfig);
};

export const getAgeRecommendation = universeId => {
  const urlConfig = getAgeRecommendationConfig();

  const data = {
    universeId
  };

  return httpService.post(urlConfig, data);
};
