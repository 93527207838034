const urls = {
  usersUniversesListUrl: '/v1/user/universes',
  impressionCostEstimateUrl: '/sponsored-games/v1/sponsored-game/estimations',
  createAdUrl: '/v2/sponsored-games/create',
  userSponsorshipsListUrl: '/sponsorships/list',
  groupSponsorshipsListUrl: '/sponsorships/list/group/{groupId}',
  adsMetadataUrl: '/ads-management-api/v1/metadata'
};

export default urls;
