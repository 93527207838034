import { DisplayNames } from 'Roblox';
import groupsModule from '../groupsModule';

function groupsService($q, httpService, groupsConstants, $filter) {
  'ngInject';

  let configurationMetadata;
  let settings;
  let configureGroupRules;

  return {
    getGroup(groupId) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getGroup, { id: groupId })
      };

      return httpService.httpGet(config, {}).then(result => {
        if (result?.owner) {
          const currentUser = result.owner;
          const { displayName, username } = currentUser;
          currentUser.nameForDisplay = DisplayNames.Enabled() ? displayName : username;
        }
        return result;
      });
    },

    getGroupMetadata() {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getGroupMetadata)
      };

      return httpService.httpGet(config);
    },

    getGroupConfigurationMetadata() {
      return $q((resolve, reject) => {
        if (configurationMetadata) {
          resolve(configurationMetadata);
          return;
        }

        const config = {
          url: $filter('formatString')(groupsConstants.urls.getGroupConfigurationMetadata)
        };

        httpService.httpGet(config).then(response => {
          configurationMetadata = response;
          resolve(response);
        }, reject);
      });
    },

    getGroupRoles(groupId) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getGroupRoles, { id: groupId }),
        retryable: false
      };

      return httpService.httpGet(config);
    },

    getGroupRolePermissions(groupId, roleSetId) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getGroupRolePermissions, {
          groupId,
          roleSetId
        })
      };

      return httpService.httpGet(config, {});
    },

    exileUser(groupId, userId) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.updateUserRole, {
          groupId,
          userId
        })
      };

      return httpService.httpDelete(config);
    },

    deleteWallPostsByUser(groupId, userId) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.deleteWallPostsByUser, {
          groupId,
          userId
        })
      };

      return httpService.httpDelete(config);
    },

    getGroupSettings(groupId) {
      return $q((resolve, reject) => {
        if (settings) {
          resolve(settings);
          return;
        }

        const config = {
          url: $filter('formatString')(groupsConstants.urls.updateGroupSettings, { id: groupId })
        };

        httpService.httpGet(config, {});

        httpService.httpGet(config).then(response => {
          settings = response;
          resolve(settings);
        }, reject);
      });
    },

    updateGroupSettings(groupId, updateGroupSettingsRequest) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.updateGroupSettings, { id: groupId })
      };

      return httpService.httpPatch(config, updateGroupSettingsRequest);
    },

    getUserIdsFromUsernames(usernames, excludeBannedUsers = false) {
      return $q((resolve, reject) => {
        const config = {
          url: $filter('formatString')(groupsConstants.urls.usernames)
        };

        const request = {
          usernames,
          excludeBannedUsers
        };

        httpService.httpPost(config, request).then(
          result => {
            resolve(result.data);
          },
          error => {
            reject(error);
          }
        );
      });
    },

    getUserRoleInGroup(userId, groupId) {
      return $q((resolve, reject) => {
        const config = {
          url: $filter('formatString')(groupsConstants.urls.getGroupRolesForUser, {
            userId
          })
        };

        httpService.httpGet(config).then(
          result => {
            if (result.data) {
              const currentGroup = result.data.filter(groupData => {
                return groupData.group.id === groupId;
              });
              if (currentGroup && currentGroup.length > 0) {
                resolve(currentGroup[0].role);
              } else {
                resolve(null);
              }
            }
          },
          () => {
            reject('Could not fetch user groups. Please try again.');
          }
        );
      });
    },

    getGroupDetailRules() {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getGroupDetailsPolicy)
      };

      return httpService.httpGet(config);
    },

    getConfigureGroupRules() {
      return $q((resolve, reject) => {
        if (configureGroupRules) {
          resolve(configureGroupRules);
          return;
        }

        const config = {
          url: $filter('formatString')(groupsConstants.urls.getConfigureGroupPolicy)
        };

        httpService.httpGet(config).then(response => {
          configureGroupRules = response;
          resolve(response);
        }, reject);
      });
    },

    getGroupPolicyInfo(groupIds) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.getGroupPolicyInfo)
      };

      const request = {
        groupIds
      };

      return httpService.httpPost(config, request);
    },

    getGroupCurrency(groupId) {
      const urlConfig = {
        url: $filter('formatString')(groupsConstants.urls.getCurrency, { groupId })
      };

      return $q((resolve, reject) => {
        return httpService.httpGet(urlConfig).then(
          response => {
            resolve(response.robux);
          },
          response => {
            const errorCodes = httpService.getApiErrorCodes(response);
            reject(errorCodes[0] || 0);
          }
        );
      });
    },

    getUserCurrency(userId) {
      const urlConfig = {
        url: $filter('formatString')(groupsConstants.urls.getUserCurrency, { userId })
      };

      return $q((resolve, reject) => {
        return httpService.httpGet(urlConfig).then(
          response => {
            resolve(response.robux);
          },
          response => {
            const errorCodes = httpService.getApiErrorCodes(response);
            reject(errorCodes[0] || 0);
          }
        );
      });
    },

    getAddFundsAllowed(groupId) {
      const urlConfig = {
        url: $filter('formatString')(groupsConstants.urls.getAddFundsAllowedUrl, {
          groupId
        })
      };

      // Always resolve
      return $q(resolve => {
        return httpService.httpGet(urlConfig).then(
          canAddFunds => {
            resolve(canAddFunds);
          },
          () => {
            resolve(false);
          }
        );
      });
    },

    getPreviousGroupNames(groupId) {
      const config = {
        url: $filter('formatString')(groupsConstants.urls.groupNameHistory, {
          id: groupId
        })
      };

      return httpService.httpGet(config, {});
    }
  };
}

groupsModule.factory('groupsService', groupsService);

export default groupsService;
