import groupsModule from '../groupsModule';

const groupResources = {
  templates: {
    groupsListBaseTemplate: 'groups-list-base',
    groupsListTemplate: 'groups-list',
    groupAboutTemplate: 'group-about',
    groupBaseTemplate: 'group-base',
    groupCommentsTemplate: 'group-comments',
    groupMembersListTemplate: 'group-members-list',
    groupGamesTemplate: 'group-games',
    GroupResultsBaseTemplate: 'group-results-base',
    groupSearchBarTemplate: 'group-search-bar',
    groupTabTemplate: 'group-tab'
  },
  modals: {
    openedClass: 'modal-open-noscroll',
    exileUser: {
      templateUrl: 'exile-user-modal',
      controller: 'exileUserController'
    },
    leaveGroup: {
      templateUrl: 'leave-group-modal',
      controller: 'leaveGroupController'
    },
    reportAbuse: {
      templateUrl: 'report-abuse-modal',
      controller: 'reportAbuseController'
    }
  }
};

groupsModule.constant('groupResources', groupResources);

export default groupResources;
