import { DisplayNames } from 'Roblox';
import { initRobloxBadgesFrameworkAgnostic } from 'roblox-badges';
import groupModule from '../groupModule';

function groupMembersListController(
  $scope,
  $log,
  $filter,
  groupDetailsService,
  groupDetailsConstants,
  cursorPaginationService,
  thumbnailConstants,
  groupsConstants
) {
  'ngInject';

  const ctrl = this;

  $scope.membersPager = cursorPaginationService.createPager({
    sortOrder: cursorPaginationService.sortOrder.Desc,
    pageSize: groupDetailsConstants.membersPageSize,
    loadPageSize: groupDetailsConstants.cursorPageLoadSize,

    getCacheKeyParameters() {
      return {
        // params will only have the query strings, not the actual URL params that we care about
        groupId: ctrl.groupId,
        roleId: ctrl.data.currentRoleId
      };
    },

    getRequestUrl() {
      return $filter('formatString')(groupsConstants.urls.getGroupRoleMembers, {
        groupId: ctrl.groupId,
        roleId: ctrl.data.currentRoleId
      });
    },

    loadSuccess(members) {
      const membersDict = {};
      const memberIds = [];
      members.forEach(function (member) {
        const memberId = member.userId;
        memberIds.push(memberId);
        membersDict[memberId] = member;
      });
      // Swap out members dictionary all at once to prevent weird loading
      ctrl.membersDict = membersDict;
      ctrl.members = members;
      try {
        initRobloxBadgesFrameworkAgnostic({
          overrideIconClass: 'verified-badge-icon-group-members-list'
        });
      } catch (e) {
        // noop
      }
    },

    loadError(errors) {
      ctrl.members = [];
      ctrl.loadMembersError = true;
      $log.debug(' ------ getGroupRoleMembers error -------');
    }
  });

  ctrl.profilePageUrl = function (userId) {
    return groupDetailsService.profilePageUrl(userId);
  };

  ctrl.updateRole = function (currentRole) {
    ctrl.loadMembersError = false;
    // Set the new role
    ctrl.data.currentRoleId = currentRole.id;
    ctrl.data.currentRoleName = currentRole.name;
    ctrl.data.currentRoleMemberCount = currentRole.memberCount || 0;
    // Load first page
    $scope.membersPager.loadFirstPage();
  };

  ctrl.setup = function () {
    ctrl.data = {
      currentRoleId: -1,
      currentRoleName: '',
      currentRoleMemberCount: 0
    };
    ctrl.roles.forEach(function (role) {
      role.roleData = {
        name: 'grouproleset',
        id: role.id
      };
    });
    ctrl.thumbnailTypes = thumbnailConstants.thumbnailTypes;
    ctrl.isDisplayNamesEnabled = DisplayNames.Enabled();
  };

  const init = function () {
    ctrl.setup();
    if (ctrl.roles.length > 0) {
      ctrl.updateRole(ctrl.roles[0]);
    } else {
      ctrl.members = [];
      ctrl.loadMembersError = true;
    }
  };

  ctrl.$onInit = init;
}

groupModule.controller('groupMembersListController', groupMembersListController);
export default groupMembersListController;
